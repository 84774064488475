"use strict";

let sliderTrack = document.querySelector(".c-banners-slider__container");
let sliderItems = document.querySelectorAll(".c-banners-slider__item");
let sliderItemWidth = sliderItems[0].offsetWidth;
let sliderTransform = 0;
let sliderItemTransform = 0;
let position = 0;
let slidesArr = [];
let sliderStart = function () {
  sliderTransform -= sliderItemWidth;
  sliderTrack.style.transform = `translateX(${sliderTransform}px)`;

  slidesArr[position].transform += sliderItemWidth * slidesArr.length;

  slidesArr[
    position
  ].slide.style.transform = `translateX(${slidesArr[position].transform}px)`;

  slidesArr.forEach((item, index) => {
    item.slide.classList.remove("is-show");
  });

  console.log(position);
  if (position + 1 >= slidesArr.length) {
    position = 0;
    slidesArr[position].slide.classList.add("is-show");
  } else {
    position++;
    slidesArr[position].slide.classList.add("is-show");
  }
};
let intervalSliderShow = setInterval(sliderStart, 2000);

sliderItems.forEach((item, index) => {
  slidesArr.push({ slide: item, position: index, transform: 0 });
});

window.addEventListener("resize", function () {
  sliderItemWidth = sliderItems[0].offsetWidth;
  sliderTransform = 0;
  sliderItemTransform = 0;
  position = 0;
  sliderTrack.style.transform = `translateX(${sliderTransform}px)`;
  slidesArr.forEach((item) => {
    item.transform = 0;
    item.slide.style.transform = `revert`;
    item.slide.classList.remove("is-show");
  });
  sliderStart();
});

sliderStart();

sliderTrack.addEventListener("mouseover", function () {
  clearInterval(intervalSliderShow);
});

sliderTrack.addEventListener("mouseout", function () {
  intervalSliderShow = setInterval(sliderStart, 5000);
});
